
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { forkJoin } from "rxjs";
import * as _ from "lodash";
import Papa from "papaparse";
import { createDeviceByCsV } from "@/store/api/devices";

export default defineComponent({
  name: "connections",
  components: {},
  setup() {
    const file = ref("");
    const content = ref([]);
    const parsed = ref<boolean>(false);
    const variant = ref<string>("");
    const respCSV = ref<any>({
      added: [],
      errors: [],
      skipped: [],
      success: false,
    });
    let response: any = {};
    onMounted(() => {
      setCurrentPageBreadcrumbs("Connections", ["Devices"]);
    });

    const handleFileUpload = (event) => {
      file.value = event.target.files[0];
      parseFile();
    };

    const parseFile = () => {
      Papa.parse(file.value, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          content.value = results;
          parsed.value = true;
        }.bind(this),
      });
    };

    const submit = () => {
      let payload = {
        variant: variant.value,
        devices: content.value["data"],
      };

      let chunk = [...createChunks(payload.devices, 50)];

      let ajax_calls = ref<any[]>([]);

      for (var i = 0; i < chunk.length; i++) {
        ajax_calls.value.push(
          createDeviceByCsV({ devices: chunk[i], variant: payload.variant })
        );
      }

      forkJoin(ajax_calls.value).subscribe(
        (data) => {
          for (var i = 0; i < chunk.length; i++) {
            respCSV.value = _.mergeWith(response, data[i], joinResults);
          }
          response = {};
        },
        (err) => {
          console.log(err);
        }
      );
    };

    const isObject = (val: boolean) => {
      return typeof val === "object";
    };

    return {
      parsed,
      content,
      respCSV,
      variant,
      handleFileUpload,
      submit,
      isObject,
    };
  },
});

function* createChunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

function joinResults(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}
